@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #212329;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #525252;
  border: 1px solid #696969;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}

